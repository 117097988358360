import api from '../axios'
const resource = '/api/mp/'
const resourceProfile = '/api/profile/'

export default {
  salariedStaffList: (filterQuery,page, paginate ,sort='',desc=true, role) =>
    api.get(`${resource}settings/users?page=${page}&paginate=${paginate}&sort=${desc?'':'-'}${sort}&filter[role_name]=${role}&${filterQuery}`),



  //staff profile route
  getStaffShifts:(id, page, paginate) =>api.get(`${resource}salaried-staff/${id}/shifts?page=${page}&paginate=${paginate}`),
  updateSalariedStaffPersonalInfo:(id, payload) =>api.put(`${resource}salaried-staff/profile/${id}/personal-info/update`,payload),

  //  salary staff file
  getImage:(payload) => api.post(`${resource}salaried-staff/files`,payload),
  getImagesList:(page, paginate, filterQuery, sort = '', desc = true) => api.get(`${resource}salaried-staff/files/?page=${page}&paginate=${paginate}&sort=${
    desc ? '' : '-'
  }${sort}&${filterQuery}`),
  downloadImage:(id) => api.get(`${resource}salaried-staff/files/${id}/download`),
  deleteToFile:(id) => api.delete(`${resource}salaried-staff/files/${id}`),

  //salaried staff profile
  profileUpload:(id, payload) => api.post(`api/profile/user/${id}/image`, payload),
  profileRemove:(id) => api.delete(`api/profile/user/${id}/image/remove`),

  getQualificationsList : () => api.get(`${resourceProfile}qualification-list`),
  updateProfessionalInfo : (payload,id) => api.post(`${resource}salaried-staff/profile/${id}/professional-info/update`,payload),
  updateContactInfo : (id,payload) => api.post(`${resource}salaried-staff/profile/${id}/contact-info/update`,payload),
  updateEmploymentInformation : (payload,id) => api.post(`${resource}salaried-staff/profile/${id}/employment-info/update`,payload),
  getUserEmployInformation : (id) => api.get(`${resource}salaried-staff/profile/${id}/employment-info/show`),

  // Locums
  getLocumRequests:(page, paginate, filterQuery) => api.get(`${resource}practice/locum-requests?page=${page}&paginate=${paginate}&filter[state]=approval pending&${filterQuery}`),
  getConnectedLocums:(page, paginate, filterQuery) => api.get(`${resource}practice/locums?page=${page}&paginate=${paginate}&${filterQuery}`),
  approveLocumRequests:(id) => api.post(`${resource}practice/locum-requests/${id}/approve`),
  declineLocumRequests:(id) => api.post(`${resource}practice/locum-requests/${id}/decline`),

  getJobRoles:() =>api.get(`api/job-roles`),

  //user archie and restore
  removeUser:(id) =>api.put(`${resource}settings/users/remove/${id}`),
  addAgain:(id) =>api.put(`${resource}settings/users/add/${id}`)

}
